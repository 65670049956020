import React from 'react';
import {Grid} from "@mui/material";
import Header from "../Qr/Header";
import {Outlet} from "react-router-dom";
import Footer from "../Qr/Footer";

function AdmLayout(props) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Header />
      </Grid>
      <Grid item xs={12}>
        <main>
          <Outlet />
        </main>
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  );
}

export default AdmLayout;