import React, {useState} from 'react';
import {Box, Button, Grid, Stack, TextField, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import Image from "mui-image";
import Api from "../api";

function IndexPage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const fromPage = location.state?.from?.pathname || "/";
  const [login, setLogin] = useState();
  const [password, setPassword] = useState();
  const [loggingIn, setLoggingIn] = useState(false);

  const performLogin = ()=>{
    setLoggingIn(true);
    fetch(`${process.env.REACT_APP_RESTO_API}/auth/login`,
      {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({login: login, password: password})
      })
      .then(resp => resp.json())
      .finally(()=>setLoggingIn(false))
  }
  return (
    <Grid container>
      <Grid item xs={12} md={6} lg={8}>
        <Image src={Api.fileUrl('bg.jpg')} fit={"cover"} />
        <Box sx={{height:'100%', display:"flex", alignItems:"center", justifyContent:"center"}}
          xs={{display:"none"}} md={{display:"flex"}}>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Stack
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
          display={"flex"}
          width={"100%"}
          minHeight={"100vh"}
          p={2}
          alignItems={"center"} justifyContent={'center'}
        >
          <Typography m={2} variant={"h5"}>
            Вход
          </Typography>
          <TextField sx={{width:"100%"}} onChange={(e) => setLogin(e.target.value)} label="Логин" variant="outlined" />
          <TextField fullWidth label="Пароль" variant="outlined"
             onChange={(e) => setPassword(e.target.value)}
             type="password"
             autoComplete="current-password"
          />
          <Button variant="contained" onClick={performLogin}>Войти</Button>
        </Stack>

      </Grid>

    </Grid>
  );
}

export default IndexPage;