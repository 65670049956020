import React from 'react';
import {Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer} from "@mui/material";
import HeaderButton from "./HeaderButton";
import MenuIcon from '@mui/icons-material/Menu';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {Link, Navigate, useParams} from "react-router-dom";
import HeaderLink from "./HeaderLink";

function Header(props) {

  const {place} = useParams();

  const [drawer, setDrawer] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawer(open);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );



  return (
    <Box sx={{position: 'absolute', zIndex:100, color:'white', padding: '8px 16px'}}
        display={'flex'}
        width={'100%'}
        justifyContent={'space-between'}
    >
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <React.Fragment>
          <HeaderButton onClick={toggleDrawer(true)}><MenuIcon /></HeaderButton>
          <SwipeableDrawer
            anchor={'left'}
            open={drawer}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            {list('left')}
          </SwipeableDrawer>
        </React.Fragment>
      </Box>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        {place && <HeaderLink to={`/p/${place}/menu`}><RestaurantMenuIcon fontSize={'small'} /> Меню</HeaderLink>}

      </Box>
    </Box>
  );
}

export default Header;