import React from 'react';
import Api from "../../api";
import {Card, CardContent, CardMedia, Typography} from "@mui/material";
import {Link, useParams} from "react-router-dom";

function CategoryLinkCard({category}) {
  const {place}= useParams();
  return (
    <Link to={`/p/${place}/menu/${category.id}`} style={{textDecoration: "none", marginRight:'0.5rem'}}>
      <Card sx={{ width: 100, height: '100%' }}>
        <CardMedia
          component="img"
          height="69"
          image={Api.fileUrl(category.image)}
          alt={category.name}
        />
        <CardContent sx={{padding: "8px"}}>
          <Typography gutterBottom fontSize={12} component="div" fontWeight={'bold'}>
            {category.name}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
}

export default CategoryLinkCard;