import React from 'react';
import Api from "../../api";
import {Box, Card, CardContent, CardMedia, Chip, ListItem, Typography} from "@mui/material";

function GoodsCard({goods}) {
  const tags = JSON.parse(goods.tags);
  return (
    <Card sx={{ display: 'flex', minHeight : '116px'}}>
      <CardMedia
        component="img"
        sx={{ width: 116 }}
        image={Api.fileUrl(goods.image)}
        alt={goods.name}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', padding: '8px' }}>
        <CardContent sx={{ flex: '1 0 auto', padding: 0, marginBottom: '8px'}}>
          <Typography component="div" variant={'h6'} fontSize={16} sx={{marginBottom:'6px'}}>
            {goods.name}
          </Typography>
          <Box sx={{display: 'flex', flexWrap:'wrap'}}>
            {tags.map((i, key) =>
                <Chip size={'small'} sx={{fontSize: '10px', marginRight:'4px'}} label={i} key={key} />
            )}
          </Box>
        </CardContent>
        <Box sx={{flexGrow:1}} />
        <Box>
          <Typography color={'#D8B46A'} fontSize={16}>
            {goods.price}&nbsp;₽
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

export default GoodsCard;