import React from 'react';
import {styled} from "@mui/material";
import {Link} from "react-router-dom";

const HeaderLink = styled(Link)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  padding: '12px 12px',
  lineHeight: 1.5,
  backgroundColor: 'rgba(255, 255, 255, 0.1);',
  color: 'white',
  backdropFilter: "blur(5px)",
  border: "none",
  outline: 'none',
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.3);',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: 'rgba(255, 255, 255, 0.3);',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

export default HeaderLink;