import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import QrCodeNotExists from "../../components/Qr/QrCodeNotExists";
import {CircularProgress} from "@mui/material";

function QrIndexPage(props) {
  const {code}= useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  let headers = new Headers();

  useEffect(()=>{
    setLoading(true);
    fetch(`${process.env.REACT_APP_RESTO_API}/public/qr?code=${code}`, {headers:headers})
      .then(res => res.ok ? res.json() : null)
      .then(({qr}) => setData(qr))
      .catch()
      .finally(()=>  setLoading(false))
  }, [code])
  console.log(data);
  if(!data)
    return <QrCodeNotExists />

  return (
    <div>
      {loading && <CircularProgress />}
      {!loading && <div>Loaded</div>}
    </div>
  );
}

export default QrIndexPage;