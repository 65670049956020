import {Navigate, Route, Routes} from "react-router-dom";
import IndexPage from "./Pages/IndexPage";
import QrLayout from "./components/Layouts/QrLayout";
import AdmLayout from "./components/Layouts/AdmLayout";
import QrIndexPage from "./Pages/QrPages/QrIndexPage";
import PageNotExistsPage from "./Pages/PageNotExistsPage";
import QrMenuPage from "./Pages/QrPages/QrMenuPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<IndexPage />}>
        <Route index element={<IndexPage />}/>
      </Route>
      <Route path="qr/:code" element={<QrIndexPage />} />
      <Route path="p/:place/*" element={<QrLayout />}>
        <Route index element={<Navigate to='menu' replace/>}/>
        <Route path="menu" element={<QrMenuPage />} />
        <Route path="menu/:category" element={<QrMenuPage />} />
      </Route>
      <Route path="adm" element={<AdmLayout />}>
        <Route index element={<Navigate to='/' replace/>}/>
      </Route>
      <Route path="*" element={<PageNotExistsPage />} />
    </Routes>
  );
}

export default App;
