import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import QrCodeNotExists from "../../components/Qr/QrCodeNotExists";
import {Box, CircularProgress, Container, Grid, Paper, Stack, Typography} from "@mui/material";
import Api from "../../api";
import GoodsCard from "../../components/Qr/GoodsCard";
import Image from "mui-image";
import CategoryLinkCard from "../../components/Qr/CategoryLinkCard";

function QrMenuPage(props) {
  const [data, setData] = useState([]);
  const [cat, setCat] = useState([]);
  const [loading, setLoading] = useState(true);

  const {place, category} = useParams();
  console.log("params", place, category);


  useEffect(()=>{
    setLoading(true);
    Api.post('/public/menu', {place:place, category:category})
      .then(res => res.ok ? res.json() : null)
      .then((data) => setData(data))
      .catch()
      .finally(()=>  setLoading(false))
    Api.post('/public/categories', {place:place, category:category})
      .then(res => res.ok ? res.json() : null)
      .then((data) => setCat(data))
      .catch()
      .finally(()=>  {})
  }, [place, category])
  console.log(data);

  if(loading)
    return (<CircularProgress />);

  const menu = data.category;
  console.log("categories", cat);

  return (
    <>
      <Box height={"240px"}>
        <Image height={"100%"} src={Api.fileUrl(data.category.image ? data.category.image : data.place.image) } />
      </Box>
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {cat.categories && cat.categories.length > 0 &&
            <Paper sx={{borderRadius: '10px', transform:"translateY(-20px)", }}>
              <Box sx={{padding:'0.5rem'}}>
                <Typography fontWeight={'bold'}>
                  Категории
                </Typography>
              </Box>
              <Box display={'flex'} width={'100%'} p={2} sx={{overflowX:'scroll'}} flexWrap={'nowrap'}>
                {cat.categories.map((c, i)=> <CategoryLinkCard category={c} key={i} />)}
              </Box>
            </Paper>}
          </Grid>
          <Grid item xs={12}>
            <Box>
              {data.category && data.category.parent_id > 0 &&
                <Link to={`/p/${place}/menu/${data.category.parent_id}`}>Назад</Link>
              }
              {data.category && data.category.parent_id == 0 &&
                <Link to={`/p/${place}/menu`}>Назад</Link>
              }
            </Box>

          </Grid>
          {data.goods.map((i, index)=>
            <Grid item xs={12} md={6} lg={4} key={index}>
              <GoodsCard goods={i}/>
            </Grid>
          )}

        </Grid>
      </Container>
    </>


  );
}

export default QrMenuPage;