function getFullEndpoint(endpoint){
  if(!endpoint.startsWith('/'))
    endpoint = '/' + endpoint;
  return `${process.env.REACT_APP_RESTO_API}${endpoint}`
}
function getImageFolder(){
  return `${process.env.REACT_APP_RESTO_HOST}/img`
}

const Api = {
  fileUrl: function(file){
    if(!file)
      return null;
    if(!file.startsWith('/'))
      file = '/' + file;
    let fileName = getImageFolder() + file;
    return fileName;
  },

  post: function(endpoint, data = {}){
    endpoint = getFullEndpoint(endpoint);
    return fetch(`${endpoint}`,
      {
        method:"post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }
    )
  }

}



export default Api;